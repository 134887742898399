<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <v-select
        v-model="value.locationId"
        :items="locations"
        label="Location"
        outlined
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { db } from '../../../plugins/firebase'
export default {
  name: 'MAnsprechpartnerForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null,
    locations: []
  }),
  async mounted() {
    const { docs } = await db.collection('dresen-standorte-renew').get()
    this.locations = docs.map(x => ({ value: x.id, text: x.data().uri }))
  }
}
</script>
